<template>
  <b-sidebar
    id="add-new-org-sidebar"
    :visible="isAddNewOrgSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-org-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Org
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Brand Name"
            rules="required|min:3|max:100"
          >
            <b-form-group
              label="Brand Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="orgData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                autocomplete="off"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Full Legal Name"
          >
            <b-form-group
              label="Full Legal Name"
              label-for="full_name"
              rules="min:3|max:100"
            >
              <b-form-input
                id="full_name"
                v-model="orgData.full_name"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                autocomplete="off"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="email"
          >
            <b-form-group
              label="Public email address"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="orgData.email"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                autocomplete="off"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Website -->
          <validation-provider
            #default="validationContext"
            name="Website"
          >
            <b-form-group
                    label="Website"
                    label-for="website"
            >
              <b-form-input
                id="website"
                v-model="orgData.website"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                autocomplete="off"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Domains -->
          <validation-provider
            #default="validationContext"
            name="Domains"
          >
            <b-form-group
              label="Domains"
              label-for="domains"
            >
              <b-form-input
                id="domains"
                v-model="orgData.domains"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                autocomplete="off"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Industry -->
          <validation-provider
            #default="validationContext"
            name="Industry"
            rules="required"
          >
            <b-form-group
              label="Industry"
              label-for="industry"
            >
              <b-form-input
                id="industry"
                v-model="orgData.industry"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                autocomplete="off"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Country -->
          <validation-provider
            #default="validationContext"
            name="Country"
            rules="required"
          >
            <b-form-group
              label="Country"
              label-for="country"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="orgData.country"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countries"
                :state="getValidationState(validationContext)"
                :reduce="val => val.value"
                :clearable="false"
                input-id="country"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/countries-data.js'
import store from '@/store'
import router from '@/router'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewOrgSidebarActive',
    event: 'update:is-add-new-org-sidebar-active',
  },
  props: {
    isAddNewOrgSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    // Use toast
    const toast = useToast()

    const blankOrgData = {
      name: '',
      full_name: '',
      domains: '',
      website: '',
      industry: '',
      country: '',
    }

    const orgData = ref(JSON.parse(JSON.stringify(blankOrgData)))
    const resetOrgData = () => {
      orgData.value = JSON.parse(JSON.stringify(blankOrgData))
    }

    const onSubmit = () => {
      store.dispatch('app/addOrg', orgData.value)
        .then(response => {
          emit('refetch-data')
          emit('update:is-add-new-org-sidebar-active', false)

          router.push({ name: 'orgs-view', params: {id: response.data.results[0].id} })
        })
        .catch(error => {
          if(typeof error.response === 'undefined') {
            //log error
            console.error(error)
          } else {
            //alert user to error
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error creating new organisation.',
                text: 'ERROR: ' + error.response.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            console.log(error.response.data.message)
          }
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetOrgData)

    return {
      orgData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-org-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
